import { Montserrat } from 'next/font/google';
import LocalFont from 'next/font/local';

const montserratFont = Montserrat({
  weight: ['400', '700'],
  style: ['normal'],
  subsets: ['latin'],
});

const gothamFont = LocalFont({
  src: [
    {
      path: '../../public/next/fonts/Gotham-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../../public/next/fonts/Gotham-Book.woff2',
      weight: '300',
      style: 'normal',
    },
  ],
});

export { montserratFont, gothamFont };
